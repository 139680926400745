import React, { useState, useEffect, useContext } from "react";
import CircularLoader from "../../../../../components/common/CircularLoader/CircularLoader";
import Folder from "components/common/Folder/Folder";
import UploadNewFileModel from "./UploadNewFileModel";
import CreateFolderModel from "./CreateFolderModel";
import Folderr from "../../../../../assets/image14.svg";
import { baseURLImage } from "../../../../../Utils/utils";
import { BiPlus } from "react-icons/bi";
import { BsUpload } from "react-icons/bs";
import CustomButton from "components/common/Enhansment/Button/CustomButton";
import {
  ChevronRightIcon,
  DownloadIcon,
  HomeIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import BreadCrumb from "./BreadCrumb";
import moment from "moment";
import UploadFileTableFolder from "./UploadFileTableFolder";
import EditModel from "./EditModel";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";
import { USER_ROLE, documentStatus } from "Constants/constant";
import GoogleDocsViewer from "components/common/GoogleDocsViewer";
import { LoginContext } from "helpers/LoginContext";
import { FaFilter } from "react-icons/fa";
import SelectOption from "components/common/Select/Select";

const WorkerDocument = (props) => {
  const {
    role = null,
    setUpdatedFile,
    DynamicBreadCrumb,
    createNewFolderSuccess,
    createNewFolderLoading,
    createFolderRequest,
    worker,
    FolderListForHRM,
    FileListForHRM,
    removeFolderRequest,
    removeFileRequest,
    getFolderListForHRMRequest,
    getFolderListForHRMLoading,
    updateFolderRequest,
    workerId = null,
    documentList,
    isLoading,
    documents,
    setDocuments,
    getDocumentsListForProgressBar,
    getEmployeeDocuments,
    openRequiredModal,
    resetRequiredModalParentCheck,

    // unuse
    handleDynamicBreadCrumbForHRMRequest,
    workerUUID,
    DocumentUploadUrl,
    handleClear,
    requiredDocuments = false,
    cssClass,

    //Sil Props
    getFolderListForSilRequest,
    silhomeDocumentsListLoader,
    silHomeDocumentFolderList,
    silHomeDocumentFileList,
    silHomeDocumentCreateFolderRequest,
    silHomeDocumentFolderCreateLoader,
    silHomeDocumentUpdateFolderRequest,
    silHomeDocumentUploadFileRequest,
    silHomeDocumentRemoveFileRequest,
    silHomeDocumentRemoveFolderRequest,
    silHomeDocumentFolderUpdateLoader
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const bread = { name: "Required Documents" };
  const userType = localStorage.getItem("userType");

  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [isOpen, setIsopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(null);
  const [fileName, setFileName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [fileScore, setFileScore] = useState(0);
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [documentstatus, setdocumentstatus] = useState(documentStatus[0]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (openRequiredModal === true) {
      setShowTable(true);
      resetRequiredModalParentCheck();
    }
  }, [openRequiredModal]);

  const handleClickOnRequiredFolder = () => {
    setShowTable(true);
  };

  useEffect(() => {
    if (DynamicBreadCrumb) {
      setBreadCrumb(DynamicBreadCrumb);
    }
  }, [DynamicBreadCrumb]);

  const handleOnFolderClick = (item) => {
    if (role === "sil") {
      getFolderListForSilRequest({
        uuid: (worker && worker.uuid) || worker,
        folder_uuid: item.uuid,
        role: role,
      });
    } else {
      getFolderListForHRMRequest({
        uuid: (worker && worker.uuid) || worker,
        folder_uuid: item.uuid,
        role: role,
      });
    }
    setParentId(item.id);
    setBreadCrumb((prev) => [...prev, item]);
    localStorage.setItem("breadcrumb", JSON.stringify(breadCrumb));
  };

  const getBreadcrumb = (e, index) => {
    setParentId(e.id);

    if (role === "sil") {
      getFolderListForSilRequest({
        uuid: (worker && worker.uuid) || worker,
        folder_uuid: e.uuid,
        role: role,
      });
    } else {
      getFolderListForHRMRequest({
        uuid: (worker && worker.uuid) || worker,
        folder_uuid: e.uuid,
        role: role,
      });
    }
    if (breadCrumb.length >= 4) {
      if (index === 0) {
        const localBreadcrumb = JSON.parse(localStorage.getItem("breadcrumb"));
        setBreadCrumb(localBreadcrumb.slice(0, localBreadcrumb.length));
        localStorage.setItem(
          "breadcrumb",
          JSON.stringify(localBreadcrumb.slice(0, -1))
        );
      }
    } else {
      setBreadCrumb(breadCrumb.slice(0, index + 1));
    }
  };

  useEffect(() => {
    setBreadCrumb(breadCrumb.slice(0, 1));
    // getDocuments();

    if (role !== "sil") {
      getFolderListForHRMRequest({
        uuid: (worker && worker.uuid) || worker,
        role: role,
      });
    } else {
      getFolderListForSilRequest({
        uuid: (worker && worker.uuid) || worker,
        role: role,
      });
    }
    localStorage.removeItem("breadcrumb");
    setSelectedFile(null);

    return () => {
      localStorage.removeItem("breadcrumb");
    };
  }, []);

  useEffect(() => {
    if (role === "sil") {
      setFiles(silHomeDocumentFileList);
    } else {
      setFiles(FileListForHRM);
    }
  }, [FileListForHRM, silHomeDocumentFileList]);

  useEffect(() => {
    if (role === "sil") {
      setFolders(silHomeDocumentFolderList);
    } else {
      setFolders(FolderListForHRM);
    }
  }, [FolderListForHRM, silHomeDocumentFolderList]);

  const getAll = () => {
    if (role === "sil") {
      getFolderListForSilRequest({
        uuid: (worker && worker.uuid) || worker,
        role: role,
      });
    } else {
      getFolderListForHRMRequest({
        uuid: (worker && worker.uuid) || worker,
        role: role,
      });
    }
    setBreadCrumb([]);
    setParentId(null);
    setCurrentFolder(null);
    localStorage.removeItem("breadcrumb");
    setShowTable(false);
  };

  const getLabel = (label) =>
    label.substring(label.lastIndexOf("/") + 1, label.length);

  const downloadDoc = (item) => {
    const isExist = documents.find((items) => items.name === item.name);
    if (typeof isExist === "undefined") return;
    else if (isExist && isExist?.link) {
      window.open(baseURLImage(isExist.link), "_blank");
    }
  };

  useEffect(() => {
    if (responseData) {
      setDocuments([...documents, responseData.data.data]);
      if (documents.length > 0) documents[0].status = false;
      setResponseData(null);
      if (getDocumentsListForProgressBar) getDocumentsListForProgressBar();
    }

    if (updatePermission) {
      const res = files.map((item) =>
        item.id === updatePermission.id ? updatePermission : item
      );
      setFiles(res);
      if (getDocumentsListForProgressBar) getDocumentsListForProgressBar();
    }
    setUpdatePermission(null);
  }, [responseData, documents, updatePermission]);

  useEffect(() => {
    if (isOpenCreateFolder === false) setCurrentFolder(null);
  }, [isOpenCreateFolder]);

  const getExpiry = (item) => {
    const res = documents.find((items) => {
      return items.name === item.name;
    });
    return res?.is_expire;
  };

  const getExpiryDate = (item) => {
    const res = documents.find((items) => {
      return items.name === item.name;
    });
    if (res?.expire_date) {
      return moment(res?.expire_date).format("DD-MM-YYYY");
    } else {
      return "N/A";
    }
  };

  const getPostedTime = (item) => {
    const res = documents.find((items) => items.name === item.name);
    // console.log("res", res, item);
    return res?.posted_time || "";
  };

  const getIssueDate = (item) => {
    const res = documents.find((items) => {
      return items.name === item.name;
    });
    if (res?.issue_date) {
      return moment(res?.issue_date).format("DD-MM-YYYY");
    } else {
      return "N/A";
    }
  };

  const handleFetch = (status) => {
    // Implement your handleFetch function
    console.log("Fetching data with status:", status);
  };

  const getAccessToUploadFileAndCreateFolder = () => {
    if (userType === "sp_standard_user" && role === "participant") {
      return true;
    }
    return false;
  };

  const filterOptions = ["All", "Uploaded", "Not Uploaded", "Expired"];

  const filteredDocuments = documentList?.filter((item) => {
    if (!filteredStatus || filteredStatus === "All") {
      return true; // Show all documents if no filter selected or "All" is selected
    }
    if (filteredStatus === "Uploaded") {
      return documents.some((doc) => doc.name === item.name);
    }
    if (filteredStatus === "Not Uploaded") {
      return !documents.some((doc) => doc.name === item.name);
    }
    if (filteredStatus === "Expired") {
      return documents.some((doc) => doc.name === item.name && doc.is_expire);
    }
    return true;
  });

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilteredStatus(e.target.value);
  };
  // console.log("dataaaaa", worker);
  return (
    <>
      {isOpenCreateFolder && (
        <CreateFolderModel
          role={role}
          setParentId={setParentId}
          parentId={parentId}
          updateFolder={
            role === "sil"
              ? silHomeDocumentUpdateFolderRequest
              : updateFolderRequest
          }
          createNewFolderSuccess={createNewFolderSuccess}
          createNewFolderLoading={
            role === "sil"
              ? silHomeDocumentFolderCreateLoader || silHomeDocumentFolderUpdateLoader
              : createNewFolderLoading
          }
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          id={workerId || worker?.id || worker}
          uuid={worker?.uuid}
          createFolderRequest={
            role === "sil"
              ? silHomeDocumentCreateFolderRequest
              : createFolderRequest
          }
          isOpen={isOpenCreateFolder}
          handleClose={setIsOpenCreateFolder}
          isUserGracePeriodOver={isUserGracePeriodOver}
        />
      )}
      {isOpen && (
        <UploadNewFileModel
          role={role}
          setParentId={setParentId}
          parentId={parentId}
          isOpen={isOpen}
          handleClose={setIsopen}
          // documentList={documentList}
          workerId={workerId || worker?.id || worker}
          setUpdatedFile={
            role === "sil" ? silHomeDocumentUploadFileRequest : setUpdatedFile
          }
          isUserGracePeriodOver={isUserGracePeriodOver}
        />
      )}
      {open && (
        <UploadFileTableFolder
          selectedFile={selectedFile}
          SelectedFileName={fileName}
          fileScore={fileScore}
          role={role}
          setUpdatedFile={setResponseData}
          setParentId={setParentId}
          parentId={parentId}
          isOpen={open}
          handleClose={() => {
            setOpen(false);
            setSelectedFile(null);
            setShowTable(true);
          }}
          workerId={workerId || worker?.id || worker}
          isUserGracePeriodOver={isUserGracePeriodOver}
          getEmployeeDocuments={getEmployeeDocuments}
        />
      )}
      {openEditModel && (
        <EditModel
          role={role}
          setUpdatedFile={setUpdatedFile}
          setParentId={setParentId}
          parentId={parentId}
          isOpen={openEditModel}
          handleClose={setOpenEditModel}
          workerId={workerId || worker?.id || worker}
          isUserGracePeriodOver={isUserGracePeriodOver}
        />
      )}
      <div className='flex flex-col'>
        <div className='w-full py-0 max-w-7xl mx-auto'>
          <div className='inline-block min-w-full py-2 align-middle'>
            {isLoading || silhomeDocumentsListLoader ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div>
                <div className='flex justify-between  space-x-8 text-gray-400  text-base font-md items-center'>
                  <div>
                    <nav>
                      <ol className='flex space-x-4 w-22 items-center'>
                        <div onClick={getAll} className='cursor-pointer'>
                          <span className='cursor flex-shrink-0 h-5 w-5'>
                            Documents Dashboard
                          </span>
                        </div>
                        {showTable && (
                          <div className='text-gray-400'>
                            <BreadCrumb breadCrumb={bread} index={2} key={2} />
                          </div>
                        )}

                        {breadCrumb.length > 0 && (
                          <span>
                            <ChevronRightIcon
                              className='flex-shrink-0 h-4 w-4 text-gray-400'
                              aria-hidden='true'
                            />
                          </span>
                        )}
                        {breadCrumb.length >= 4 && (
                          <>
                            <b className='mb-2'>...</b>
                            <span className='ml-0'>
                              <ChevronRightIcon
                                className='flex-shrink-0 h-4 w-4 text-gray-400'
                                aria-hidden='true'
                              />
                            </span>
                            <b className='mb-2'>...</b>
                            <span className='ml-0'>
                              <ChevronRightIcon
                                className='flex-shrink-0 h-4 w-4 text-gray-400'
                                aria-hidden='true'
                              />
                            </span>
                          </>
                        )}
                        {breadCrumb
                          .slice(breadCrumb.length >= 4 && -2)
                          .map((item, index) => (
                            <BreadCrumb
                              breadCrumb={item}
                              index={index}
                              key={index}
                              handleClick={getBreadcrumb}
                            />
                          ))}
                      </ol>
                    </nav>
                  </div>
                  {!showTable && (
                    // && !getAccessToUploadFileAndCreateFolder()
                    <div className='flex space-x-4'>
                      {(roleAccess(
                        [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
                        PERMISSION_KEYS.PARTICIPANT,
                        PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.FOLDER
                          .CREATE
                      ) ||
                        roleAccess(
                          [USER_ROLE.Sp_Standard_User],
                          role === "participant"
                            ? PERMISSION_KEYS.PARTICIPANT
                            : PERMISSION_KEYS.DOCUMENT,
                          role === "participant"
                            ? BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                                .DOCUMEMT.FOLDER.CREATE
                            : BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT
                                .FOLDER.CREATE
                        )) && (
                        <div className='flex items-center space-x-4'>
                          <CustomButton
                            clickHandler={() => setIsOpenCreateFolder(true)}
                            label='Create Folder'
                            Icon={<BiPlus />}
                            variant='wsecondary'
                          />
                        </div>
                      )}
                      {(roleAccess(
                        [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
                        PERMISSION_KEYS.PARTICIPANT,
                        PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT.UPLOAD
                      ) ||
                        roleAccess(
                          [USER_ROLE.Sp_Standard_User],
                          role === "participant"
                            ? PERMISSION_KEYS.PARTICIPANT
                            : PERMISSION_KEYS.DOCUMENT,
                          role === "participant"
                            ? BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                                .DOCUMEMT.UPLOAD
                            : BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT
                                .UPLOAD
                        )) && (
                        <div className='flex items-center space-x-4'>
                          <CustomButton
                            clickHandler={() => setIsopen(true)}
                            label='Upload Document'
                            Icon={<BsUpload />}
                            variant='wsecondary'
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className=''>
                  {getFolderListForHRMLoading ? (
                    <CircularLoader
                      classes='flex justify-center items-center col-span-4'
                      classloader='loader-l'
                    />
                  ) : (
                    <>
                      {showTable ? (
                        <div className='bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 pt-4'>
                          <div className='flex justify-end pb-0 pt-0 mb-2 pr-0'>
                            <span
                              className='pr-2 flex items-center text-light-50'
                              style={{
                                color: "#6B7280",
                                fontWeight: "600",
                                fontSize: "0.825rem",
                                lineHeight: "1.25rem",
                              }}
                            >
                              Document filter by status
                              <FaFilter className='ml-1' />{" "}
                              {/* Adjusting margin to create space between text and icon */}
                            </span>
                            <div className='w-[20%]'>
                              <SelectOption
                                //isDisabled={isLoading || renderIsLoading}
                                isSearch={false}
                                selectedOption={documentstatus}
                                handleChange={(e) => {
                                  setdocumentstatus(e);
                                  handleFetch({ status: e.value });
                                }}
                                data={documentStatus} // Here is where you provide your data
                                // cssClass='z-20'
                              />
                            </div>
                          </div>

                          <div className='flex flex-col'>
                            <div className='-my-2 -mx-4 overflow-x-auto111 scrollbar-hide1111 sm:-mx-6 lg:-mx-8'>
                              <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='table-wrp shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                                  <table className='min-w-full divide-y divide-gray-300'>
                                    <thead className='bg-blue-50 pt-2 top-[0] sticky capitalize'>
                                      <tr className=''>
                                        <th
                                          scope='col'
                                          className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                          Mandatory Documents
                                        </th>
                                        <th
                                          scope='col'
                                          className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                          Status
                                        </th>
                                        <th
                                          scope='col'
                                          className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                          Issue Date
                                        </th>
                                        <th
                                          scope='col'
                                          className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                          Expiry Date
                                        </th>
                                        <th
                                          scope='col'
                                          className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                          Uploaded At
                                        </th>
                                        <th
                                          scope='col'
                                          className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className='divide-y divide-gray-200 bg-white'>
                                      {filteredDocuments &&
                                      documentList &&
                                      filteredDocuments.length > 0 ? (
                                        filteredDocuments.map((item, index) => {
                                          const status =
                                            getExpiry(item) === true
                                              ? "Expired"
                                              : documents.find(
                                                  (items) =>
                                                    items.name === item.name
                                                )
                                              ? "Uploaded"
                                              : "Not Uploaded";

                                          // Check if the document status matches the selected dropdown value
                                          if (
                                            (!documentstatus.value &&
                                              status !== "Expired") ||
                                            documentstatus.value === "all" ||
                                            (documentstatus.value ===
                                              "uploaded" &&
                                              status === "Uploaded") ||
                                            (documentstatus.value ===
                                              "notuploaded" &&
                                              status === "Not Uploaded") ||
                                            (documentstatus.value ===
                                              "expired" &&
                                              status === "Expired")
                                          ) {
                                            return (
                                              <tr
                                                className='even:bg-white odd:bg-gray-50'
                                                key={index}
                                              >
                                                <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
                                                  {item?.name}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                  {status}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                  {getIssueDate(item)}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                  {getExpiryDate(item)}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                  {getPostedTime(item)}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                                  <button className='flex justify-center space-x-1'>
                                                    <div
                                                      onClick={() => {
                                                        const res =
                                                          documents.find(
                                                            (items) =>
                                                              items.name ===
                                                              item.name
                                                          );

                                                        if (
                                                          !res ||
                                                          res?.is_expire ===
                                                            true
                                                        ) {
                                                          setFileName(
                                                            item.name
                                                          );
                                                          setFileScore(
                                                            item.score
                                                          );
                                                          setOpen(true);
                                                        } else if (res) {
                                                          const isExist =
                                                            documents.find(
                                                              (items) =>
                                                                items.name ===
                                                                item.name
                                                            );
                                                          const obj = {};
                                                          if (
                                                            typeof isExist ===
                                                            "undefined"
                                                          ) {
                                                          } else if (isExist) {
                                                            Object.assign(obj, {
                                                              issue_date:
                                                                isExist.issue_date,
                                                              expire_date:
                                                                isExist.expire_date,
                                                              docuemnt_id:
                                                                isExist.id,
                                                              is_expire:
                                                                isExist.is_expire,
                                                              link: baseURLImage(
                                                                isExist?.link
                                                              ),
                                                            });
                                                          }
                                                          setSelectedFile({
                                                            ...item,
                                                            ...obj,
                                                          });
                                                          setFileName(
                                                            item.name
                                                          );
                                                          setFileScore(
                                                            item.score
                                                          );
                                                          setOpen(true);
                                                        }
                                                      }}
                                                      className={`icon-eye-wrapper ${
                                                        documents.find(
                                                          (items) => {
                                                            return (
                                                              items.name ===
                                                              item.name
                                                            );
                                                          }
                                                        ) &&
                                                        getExpiry(item) ===
                                                          false
                                                          ? "bg-red-500"
                                                          : ""
                                                      }`}
                                                      title='upload'
                                                    >
                                                      <UploadIcon className='icon-size' />
                                                    </div>

                                                    {/* Spacer */}
                                                    <div className='w-2'></div>

                                                    <div className='gap-x-3 flex'>
                                                      <GoogleDocsViewer
                                                        url={baseURLImage(
                                                          documents.find(
                                                            (doc) =>
                                                              doc.name ===
                                                              item.name
                                                          )?.link
                                                        )}
                                                      />
                                                    </div>

                                                    {/* Spacer */}
                                                    <div className='w-2'></div>

                                                    <div
                                                      className={`icon-eye-wrapper ${
                                                        documents.find(
                                                          (items) =>
                                                            items.name ===
                                                            item.name
                                                        )
                                                          ? ""
                                                          : "bg-red-500"
                                                      }`}
                                                      title='Download'
                                                      onClick={() =>
                                                        downloadDoc(item)
                                                      }
                                                    >
                                                      <DownloadIcon className='icon-size' />
                                                    </div>
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          } else {
                                            return null; // If status does not match selected filter, don't render the row
                                          }
                                        })
                                      ) : (
                                        // Render a message when filteredDocuments is null or empty
                                        <tr>
                                          <td
                                            colSpan='1'
                                            className='py-4 px-3 text-sm font-medium text-gray-900 text-center'
                                          >
                                            No data found!
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='grid grid-cols-4 gap-4 mt-4 capitalize'>
                          {breadCrumb.length === 0 &&
                            role !== "participant" &&
                            role !== "sil" && (
                              <div
                                className='border border-solid shadow-md rounded-lg bg-[#FFFFFF] border-gray-200 px-6'
                                onDoubleClick={handleClickOnRequiredFolder}
                              >
                                <div className='mt-2'>
                                  <div className='flex justify-between mt-[20px] items-center'>
                                    <img
                                      src={Folderr}
                                      alt='image_folder'
                                      className='h-[36px] w-[36px]'
                                    />
                                  </div>
                                  <div className='flex justify-between mt-[10px] mb-10'>
                                    <div className='whitespace-nowrap text-gray-900 font-medium text-base leading-6 truncate'>
                                      Required Documents
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {folders.length > 0 &&
                            folders.map((item, index) => (
                              <Folder
                                role={role}
                                permissionRename={getAccessToUploadFileAndCreateFolder()}
                                key={index}
                                onClick={() => handleOnFolderClick(item)}
                                createdDate={item.created_at}
                                type='folder'
                                badged={item.badged}
                                label={item.name}
                                item={item}
                                removeFolder={
                                  role === "sil"
                                    ? silHomeDocumentRemoveFolderRequest
                                    : removeFolderRequest
                                }
                                removeFile={
                                  role === "sil"
                                    ? silHomeDocumentRemoveFileRequest
                                    : removeFileRequest
                                }
                                isEdit={(e) => setIsOpenCreateFolder(e)}
                                currentFolder={(e) => setCurrentFolder(e)}
                              />
                            ))}
                          {files.length > 0 &&
                            files.map((item, index) => (
                              <Folder
                                isUserGracePeriodOver={isUserGracePeriodOver}
                                ext={item?.file_type}
                                key={index}
                                setUpdatePermission={setUpdatePermission}
                                createdDate={item.created_at}
                                type='file'
                                role={role}
                                badged='Expiry'
                                label={getLabel(item.link)}
                                item={item}
                                removeFolder={
                                  role === "sil"
                                    ? silHomeDocumentRemoveFolderRequest
                                    : removeFolderRequest
                                }
                                removeFile={
                                  role === "sil"
                                    ? silHomeDocumentRemoveFileRequest
                                    : removeFileRequest
                                }
                                isEdit={(e) => setIsopen(e)}
                                currentFolder={(e) => setCurrentFolder(e)}
                              />
                            ))}
                        </div>
                      )}

                      {folders.length === 0 &&
                      files.length === 0 &&
                      role === "participant" &&
                      breadCrumb.length === 0 ? (
                        <div className='text-center py-5 px-4 sm:py-6 sm:px-0 col-span-4'>
                          <p className='text-red-500'>No Data Found.</p>
                        </div>
                      ) : (
                        folders.length === 0 &&
                        files.length === 0 &&
                        breadCrumb.length !== 0 && (
                          <div className='text-center py-5 px-4 sm:py-6 sm:px-0 col-span-4'>
                            <p className='text-red-500'>
                              This folder is empty.
                            </p>
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkerDocument;
